
import { Col, Input, Row, Table, Tooltip } from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { allapiAction } from "../../Redux/common/action";
import { URL } from "../../Redux/common/url";
import HeadingBack from "../component/HeadingBack";
import { Button, Modal, Space } from "antd";


function Bettalview() {
  const data = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // getProfileDetails

  // user_details

  const details = useSelector((state) =>
    state?.allapi?.battels_details ? state?.allapi?.battels_details : {}
  );

  // console.log(details);

  useEffect(() => {
    dispatch(allapiAction.getbDetails({ _id: data?.id }));

    return () => { };
  }, []);

  function maskNumber(number) {
    if (number) {
      const numberString = number.toString(); // Convert the number to a string
      const maskedPortion = numberString.slice(0, -2).replace(/\d/g, "*"); // Replace all but the last 2 digits with asterisks
      const lastTwoDigits = numberString.slice(-2); // Get the last 2 digits
      return maskedPortion + lastTwoDigits;
    }
    // Combine the masked portion and last 2 digits
  }


  const handleCancel = () => {
    alert("Are you sure ?")
    dispatch(
      allapiAction.deleteByAdminBettal({
        _id: data?.id,
        // active: 0,
        pageNumber: 1,
      })
    );

    //  navigate("/battle")
    return () => { };
  };


  const handleWin = (id) => {
    alert("Are you sure ?")
    dispatch(
      allapiAction.submitwinnerAdmincencel(
        {
          gameId: details?._id,
          winnerID: id,
          resulttype: "win",
        },
        navigate
      )
    );

    return () => { };
  }


  const [userData, setuserData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [description, setDes] = useState(null);


  const hendleToViewM = (e) => {
    setuserData(e);
  };


  const hendleToSubmetUpdate = () => {

    dispatch(
      allapiAction.addpelantyByAdmin({
        id: userData,
        amount: amount,
        description: description,
        pageNumber: 1,
      })
    );

    dispatch(allapiAction.getbDetails({ _id: data?.id }));
   
    setAmount(null)
    setDes(null)
  };

  useEffect(() => {
    if (userData && amount) {
      hendleToSubmetUpdate()
    }

  }, [userData])



  return (
    <div className="code-box">


      <div className="flex">
        <div>
          <HeadingBack
            title={"Battles"}
          // subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>

      </div>
      <div className="d-flex flex-column flex-column-fluid">
        {/* <!--begin::Toolbar-->  */}
        <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
          {/* <!--begin::Toolbar container-->  */}
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-flex flex-stack"
          >
            {/* <!--begin::Page title-->  */}
            <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
              {/* <!--begin::Title-->  */}
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">
                Battles Details
              </h1>
            </div>
            {/* <!--end::Page title-->  */}
            {/* <!--begin::Actions-->  */}
          </div>
          {/* <!--end::Toolbar container-->  */}
        </div>
        {/* <!--end::Toolbar-->  */}
        {/* <!--begin::Content-->  */}
        <div id="kt_app_content" className="app-content flex-column-fluid">
          {/* <!--begin::Content container-->  */}

          <div className="flex gap-4">

            <div class="match-details">
              <button class="button" style={{ marginLeft: '130px' }} onClick={handleCancel}>Cancel Match</button>
              <h1>Match Details</h1>
              <p>Check participants data, and announced result.</p>
              <p><strong>Match Fee:</strong> {details?.amount}</p>
              <p><strong>Prize:</strong> {details?.prize}</p>
              <p><strong>Type:</strong> {details?.batteltype}</p>
              <p><strong>Status:</strong> <span class="status">{details?.status == "running"
                ? "running"
                : "not running"}</span></p>
              <p><strong>Room Code:</strong> {details?.roomcode}</p>
              <p style={{ flexShrink: '0' }}>Last Updated By: {new Date(details?.updatedAt).toLocaleString()}</p>
            </div>


            <div class="match-details-container" style={{ flex: '1' }}>
              <div class="section">
                <h2>Creator</h2>
                <p><strong>User ID:</strong> {details?.owner?.uid}</p>
                <p><strong>Hold Balance:</strong> {details?.owner?.walletId?.closeBal} &#8377;</p>
                <p><strong>Created Time:</strong> {new Date(details?.createdAt).toLocaleString()}</p>
                <p><strong>Participant Status:</strong>{ details?.ownerresult ? details?.ownerresult : "Not Declared"}</p>
                { details?.ownersreason && <p><strong>Cancel Reason:</strong> 
                <span class="highlight">{details?.ownersreason}</span></p>}
               
                <button class="win-button" onClick={() => handleWin(details?.owner?._id)}>Win</button>
                <div className="border p-2 m-3">
                  <div>
                    <p><strong>Add Penalty</strong></p>
                    <input value={details?.owner?._id === userData ? amount : null} type="text" class="penalty-input" placeholder="0" onChange={(e) => setAmount(e.target.value)} />
                    <button class="penalty-button" onClick={() => hendleToViewM(details?.owner?._id)}>Add Penalty</button>
                  </div>
                  <div style={{ margin: '10px' }}>
                    <p>Description</p>
                    <textarea value={details?.owner?._id === userData ? description : null}  onChange={(e) => setDes(e.target.value)} name="description" id="description" style={{ width: '100%', outline: 'none' }} placeholder="Write penalty description"></textarea>
                  </div>
                </div>
              </div>
              <div class="section">
                <h2>Acceptor</h2>
                <p><strong>User ID:</strong> {details?.playerone?.uid}</p>
                <p><strong>Hold Balance:</strong> {details?.playerone?.walletId?.closeBal} &#8377;</p>
                <p><strong>Join Time:</strong> {new Date(details?.updatedAt).toLocaleString()}</p>
                <p><strong>Participant Status:</strong> <span >{details?.playeroneresult?details?.playeroneresult: "Not Declared"}</span></p>
                { details?.playeronesreason && <p><strong>Cancel Reason:</strong> <span >{details?.playeronesreason}</span></p>}
               

                <button class="win-button" onClick={() => handleWin(details?.playerone?._id)}>Win</button>
                <div className="border p-2 m-3">
                  <div>
                    <p><strong>Add Penalty</strong></p>
                    <input  value={details?.playerone?._id === userData ? amount : null}  type="text" class="penalty-input" placeholder="0" onChange={(e) => setAmount(e.target.value)} />
                    <button class="penalty-button" onClick={() => hendleToViewM(details?.playerone?._id)}>Add Penalty</button>
                  </div>
                  <div style={{ margin: '10px' }}>
                    <p>Description</p>
                    <textarea value={details?.playerone?._id === userData ? description : null}  onChange={(e) => setDes(e.target.value)} name="description" id="description" style={{ width: '100%', outline: 'none' }} placeholder="Write penalty description"></textarea>
                  </div>
                </div>

              </div>
            </div>


          </div>


          {/* <!--end::Content container-->  */}
        </div>
        {/* <!--end::Content-->  */}
      </div>
    </div>
  );
}

export default Bettalview;
